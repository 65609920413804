import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Route } from '../../../types/Logistics';
import ChangeDeliveryDatePage from './ChangeDeliveryDatePage';
import { useAppSelector } from '_common/hooks/reduxHooks';
import { getOrderBySecret, getOrderByNumber } from '_common/reducers/orders';
import { Page } from '@components/page';
import { getProducerDeliveryRoutes } from 'api';
import { getDagensDeliveryDates } from 'utils/delivery';
import REQ, { combineReqs, ReqType } from 'utils/REQ';

const ChangeDeliveryDateFetcher = () => {
  const [req, setReq] = useState<ReqType>(REQ.INIT);
  const [deliveryRoutes, setDeliveryRoutes] = useState<Route[]>([]);
  const { secret } = useParams<{ secret: string }>();

  const { ordersReq, roleId, order } = useAppSelector(({ auth, orders }) => {
    return {
      roleId: auth._id,
      ordersReq: orders.req,
      order:
        getOrderBySecret(orders.items, secret) ??
        getOrderByNumber(orders.items, secret)
    };
  });

  useEffect(() => {
    async function fetchData() {
      if (ordersReq !== REQ.SUCCESS || req !== REQ.INIT) return;

      setReq(REQ.PENDING);
      const distributionAreaId = order?.consumer.distributionArea._ref;
      setDeliveryRoutes(
        await getProducerDeliveryRoutes({
          producerId: roleId,
          distributionAreaId
        })
      );
      setReq(REQ.SUCCESS);
    }
    fetchData();
  }, [req, ordersReq, roleId, order]);

  const dagensDeliveryDates = useMemo(() => {
    return getDagensDeliveryDates(deliveryRoutes, roleId);
  }, [JSON.stringify(deliveryRoutes), JSON.stringify(roleId)]);

  if (req !== REQ.SUCCESS) {
    return <Page.Status req={req} />;
  }
  if (!order) {
    return <Page.NotFound />;
  }

  return (
    <ChangeDeliveryDatePage
      req={combineReqs([req, ordersReq])}
      roleId={roleId}
      order={order}
      deliveryDates={dagensDeliveryDates}
      deliveryRoutes={deliveryRoutes}
    />
  );
};
export default ChangeDeliveryDateFetcher;
