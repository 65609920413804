export const Illustrations = {
  POTATOES_DATE: '/assets/illustrations/potatoes_date.png',
  INTERESTED_CHEF: '/assets/illustrations/interested-chef.png',
  PROUD_PRODUCER: '/assets/illustrations/proud-producer-onion-grey.png',
  PROUD_PRODUCER_BREAD: '/assets/illustrations/proud-producer-bread.png',
  CUSTOMER_CARRYING_ONION_RED:
    '/assets/illustrations/customer-carrying-onion-red.png',
  CUSTOMER_RECEIVING_ONION_RED:
    '/assets/illustrations/customer-receiving-onion-red.png',
  CUSTOMER_CARRYING_ONION_GREEN:
    '/assets/illustrations/customer-carrying-onion-green.jpg',
  LADY_CYCLING_SWEET_POTATO_BLUE:
    '/assets/illustrations/lady-cycling-sweet-potato-blue.png',
  CUSTOMER_CARRYING_MANY_PRODUCE:
    '/assets/illustrations/customer-carrying-many-produce.png',
  PRODUCER_PLACING_PRODUCE_IN_IFCO:
    '/assets/illustrations/producer-placing-produce-in-ifco.png',
  SO_MUCH_TO_CHOOSE_FROM: '/assets/illustrations/so-much-to-choose-from.png',
  BIKE_DELIVERY: '/assets/illustrations/bike-delivery.png',
  CARROT_SHOW_OFF: '/assets/illustrations/carrot-show-off.png',
  ONION_MAN: '/assets/illustrations/onion-man.png',
  STOCKING_UP: '/assets/illustrations/stocking-up.png',
  SUCCESSFUL_DELIVERY: '/assets/illustrations/successful-delivery.png'
};
