/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["state"] }] */
import {
  calculateOrderedUnitPrice,
  definedNumber,
  getCountAndPriceAndUnit
} from '@dagensmat/core';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getOrders, postOrderStatuses } from 'api';
import { type AppDispatch } from 'store';
import { Order, OrderKind, OrderLine, OrderStatuses } from 'types/Order';
import {
  addKey,
  byDescending,
  byFunction,
  byKey,
  byUniqueId,
  grouper
} from 'utils/array';
import { isWeek } from 'utils/date/format';
import { getPickupType } from 'utils/delivery';
import REQ, { ReqType } from 'utils/REQ';
import { formatTextWithUnits } from 'utils/texts';

/** Action creators */

type OrdersState = {
  req: ReqType;
  items: Order[];
};

const initialState: OrdersState = {
  req: REQ.INIT,
  items: []
};

const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    clearOrders(state) {
      state.req = REQ.INIT;
      state.items = [];
    },
    orderStatusUpdated(
      state,
      action: PayloadAction<{ orderId: string; statuses: OrderStatuses }>
    ) {
      const order = state.items.find(({ _id }) => {
        return _id === action.payload.orderId;
      });
      if (!order) {
        return;
      }
      order.statuses = { ...order.statuses, ...action.payload.statuses };
    },
    orderLineUpdated(state, action: PayloadAction<any>) {
      const { orderId } = action.payload;
      const orderIndex = state.items.findIndex(({ _id }) => {
        return _id === orderId;
      });

      if (orderIndex === -1) {
        return;
      }

      const order = state.items[orderIndex];

      const { lineKey } = action.payload;
      const orderLineIndex = order.orderLines.findIndex(({ _key }) => {
        return _key === lineKey;
      });

      if (orderLineIndex === -1) {
        return;
      }

      const {
        nrOfOrderedUnitsDelivered,
        nrOfPricedUnitsDelivered,
        nokPerPricedUnit,
        batchIdentification
      } = action.payload;

      state.items[orderIndex].orderLines[
        orderLineIndex
      ].nrOfOrderedUnitsDelivered = nrOfOrderedUnitsDelivered;
      state.items[orderIndex].orderLines[
        orderLineIndex
      ].nrOfPricedUnitsDelivered = nrOfPricedUnitsDelivered;
      state.items[orderIndex].orderLines[
        orderLineIndex
      ].nrOfPricedUnitsDelivered = nrOfPricedUnitsDelivered;
      state.items[orderIndex].orderLines[
        orderLineIndex
      ].pricingAtTimeOfOrder.nokPerPricedUnit = nokPerPricedUnit;
      state.items[orderIndex].orderLines[orderLineIndex].batchIdentification =
        batchIdentification;
    },
    batchIdentificationUpdated(state, action: PayloadAction<any>) {
      const { orderId } = action.payload;
      const orderIndex = state.items.findIndex(({ _id }) => {
        return _id === orderId;
      });

      if (orderIndex === -1) {
        return;
      }

      const order = state.items[orderIndex];

      const { lineKey } = action.payload;
      const orderLineIndex = order.orderLines.findIndex(({ _key }) => {
        return _key === lineKey;
      });

      if (orderLineIndex === -1) {
        return;
      }

      const { batchIdentification } = action.payload;

      state.items[orderIndex].orderLines[orderLineIndex].batchIdentification =
        batchIdentification;
    },
    fetchOrdersRequest(state) {
      state.req = REQ.PENDING;
      state.items = [];
    },
    fetchOrdersError(state) {
      state.req = REQ.ERROR;
      state.items = [];
    },
    fetchOrdersSuccess(state, action: PayloadAction<Order[]>) {
      state.req = REQ.SUCCESS;
      state.items = [...action.payload, ...state.items]
        .filter(byUniqueId)
        .sort(byKey('deliveryDate'));
    }
  }
});

export default ordersSlice.reducer;
export const {
  clearOrders,
  orderStatusUpdated,
  orderLineUpdated,
  batchIdentificationUpdated,
  fetchOrdersRequest,
  fetchOrdersSuccess,
  fetchOrdersError
} = ordersSlice.actions;

type ConsumerOrProducer =
  | { consumerId?: string; producerId?: never }
  | { producerId?: string; consumerId?: never };

export const fetchOrders = (
  params: ConsumerOrProducer,
  { clearStore = true } = {}
) => {
  return (dispatch: AppDispatch) => {
    if (clearStore) {
      dispatch(fetchOrdersRequest());
    }

    getOrders(params)
      .then(orders => {
        dispatch(fetchOrdersSuccess(orders));
      })
      .catch(() => {
        dispatch(fetchOrdersError());
      });
  };
};

const updateOrderStatus = (orderId: string, statuses: OrderStatuses) => {
  return (dispatch: AppDispatch) => {
    dispatch(
      orderStatusUpdated({
        orderId,
        statuses
      })
    );
  };
};

export const postAndUpdateOrderStatuses = (
  orderIds: string[],
  status: keyof OrderStatuses
) => {
  return async (dispatch: AppDispatch) => {
    const result = await postOrderStatuses({ orderIds, status });
    return result.map(
      ({ _id, statuses }: { _id: string; statuses: OrderStatuses }) => {
        return dispatch(updateOrderStatus(_id, statuses));
      }
    );
  };
};

/** Selectors for order lines */

const hasOrderedUnitsDeliveredBeenUpdated = (line: OrderLine) => {
  return (
    line.nrOfOrderedUnitsDelivered !== line.nrOfUnits &&
    definedNumber(line.nrOfOrderedUnitsDelivered)
  );
};

export const hasPricedUnitsDeliveredBeenUpdated = (line: OrderLine) => {
  return line.pricingAtTimeOfOrder.orderedUnit ===
    line.pricingAtTimeOfOrder.pricedUnit
    ? definedNumber(line.nrOfPricedUnitsDelivered) &&
        line.nrOfPricedUnitsDelivered !== line.nrOfUnits
    : definedNumber(line.nrOfPricedUnitsDelivered);
};

export const hasOrderLineBeenUpdated = (line: OrderLine) => {
  return (
    hasOrderedUnitsDeliveredBeenUpdated(line) ||
    hasPricedUnitsDeliveredBeenUpdated(line)
  );
};

export const getDeliveredUnitsWithUnitText = (line: OrderLine) => {
  const { unit, count } = getCountAndPriceAndUnit(line);

  return formatTextWithUnits(unit, count);
};

export const getOrderedUnitsToBeDelivered = (line: OrderLine) => {
  return hasOrderedUnitsDeliveredBeenUpdated(line)
    ? line.nrOfOrderedUnitsDelivered
    : line.nrOfUnits;
};

export const getPricedUnitsToBeDelivered = (line: OrderLine) => {
  return hasPricedUnitsDeliveredBeenUpdated(line)
    ? line.nrOfPricedUnitsDelivered
    : line.pricingAtTimeOfOrder.pricedUnitsPerOrderedUnit *
        (getOrderedUnitsToBeDelivered(line) ?? 0);
};

export const getOrderLinePrice = (line: OrderLine) => {
  return hasPricedUnitsDeliveredBeenUpdated(line)
    ? (getPricedUnitsToBeDelivered(line) ?? 0) *
        line.pricingAtTimeOfOrder.nokPerPricedUnit
    : (getOrderedUnitsToBeDelivered(line) ?? 0) *
        calculateOrderedUnitPrice(line.pricingAtTimeOfOrder);
};

export const hasOrderLinesBeenUpdated = (lines: OrderLine[] = []) => {
  return lines.some(hasOrderLineBeenUpdated);
};

export const getTotalOrderPrice = (
  lines: OrderLine[],
  producerDeliveryFee?: number
) => {
  return (
    lines.map(getOrderLinePrice).reduce((acc, price) => {
      return acc + price;
    }, 0) + (producerDeliveryFee ?? 0)
  );
};

export const resetToOriginalOrderLines = (lines: OrderLine[]) => {
  return lines.map(line => {
    return {
      ...line,
      nrOfOrderedUnitsDelivered: undefined,
      nrOfPricedUnitsDelivered: undefined
    };
  });
};

/** Selectors for orders */

export const getTotalOrdersPrice = (orders: OrderKind[] = []) => {
  return orders.reduce((acc, order) => {
    const orderLines = order.orderLines;
    const producerDeliveryFee =
      order._type === 'transactionCutAdjustment'
        ? 0
        : order.producerDeliveryFee?.finalValue;
    return acc + getTotalOrderPrice(orderLines, producerDeliveryFee);
  }, 0);
};

export const isOpened = ({
  statuses = {} as OrderStatuses
}: {
  statuses: OrderStatuses;
}) => {
  return statuses.openedByProducer;
};

const isCancelled = ({
  statuses = {} as OrderStatuses
}: {
  statuses: OrderStatuses;
}) => {
  return 'cancelled' in statuses;
};

export const isActive = ({
  statuses = {} as OrderStatuses
}: {
  statuses: OrderStatuses;
}) => {
  return !statuses.deliveredToConsumer && !statuses.cancelled;
};

const isUnopenedAndActive = (order: Order) => {
  return !isOpened(order) && isActive(order);
};

export const isOrderInvoiceSentToDagens = (
  { statuses = {} as OrderStatuses }: { statuses: OrderStatuses } = {
    statuses: {} as OrderStatuses
  }
): boolean => {
  return 'sentToInvoicing' in statuses || 'invoiceSentToDagens' in statuses;
};

export const isOrderReadOnly = (order: Order = {} as Order) => {
  return isOrderInvoiceSentToDagens(order) || isCancelled(order);
};

export const countActiveUnopenedOrders = (orders: Order[]) => {
  return orders.filter(isUnopenedAndActive).length;
};

export const calculateActiveUnopenedValue = (orders: Order[]) => {
  const activeUnopenedOrders = orders.filter(isUnopenedAndActive);
  return getTotalOrdersPrice(activeUnopenedOrders);
};

export const getOrderBySecret = (orders: Order[], secret?: string) => {
  return orders.find(o => {
    return o.secret === secret;
  });
};

export const getOrderByNumber = (orders: Order[], orderNumber?: string) => {
  return orders.find(o => {
    return o.orderNumberString === orderNumber;
  });
};

export const splitActiveAndPastOrders = (orders: Order[]) => {
  const activeOrders = orders.filter(isActive);
  const pastOrders = orders
    .filter(o => {
      return !isActive(o);
    })
    .sort(byDescending('deliveryDate'));

  return { activeOrders, pastOrders };
};

const isDeliveredAndNotInvoiced = (order: Order = {} as Order) => {
  const { statuses: { deliveredToConsumer, cancelled } = {} } = order;
  return (
    deliveredToConsumer && !cancelled && !isOrderInvoiceSentToDagens(order)
  );
};

export const splitInvoicedAndNotInvoicedOrders = (orders: Order[]) => {
  const notInvoicedOrders = orders.filter(isDeliveredAndNotInvoiced);

  const invoicedOrders = orders
    .filter(o => {
      return isOrderInvoiceSentToDagens(o) || isCancelled(o);
    })
    .sort(byDescending('deliveryDate'));

  return { notInvoicedOrders, invoicedOrders };
};

export const groupOrdersByDeliveryDate = (
  orders: Order[] = []
): {
  key: string;
  deliveryDate: Order['deliveryDate'];
  orders: Order[];
}[] => {
  const withKey = orders.map(
    addKey(({ deliveryDate }) => {
      return deliveryDate;
    })
  );
  const groups = grouper(withKey, ['deliveryDate'], 'orders');

  return Object.values(groups);
};

export const groupOrdersByPickupType = (orders: Order[] = []) => {
  const withPickupType = orders.map(order => {
    return { ...order, ...getPickupType(order) };
  });
  const groups = grouper(
    withPickupType,
    [
      'deliveryDate',
      'type',
      'description',
      'producerDeliveryDate',
      'toName',
      'toDeadline'
    ] as any,
    'orders'
  );

  return Object.values(groups);
};

export const getOrderGroup = (
  orders: Order[],
  pickupKey: string | undefined
) => {
  return groupOrdersByPickupType(orders).find(({ key }) => {
    return key === pickupKey;
  });
};

export const getOrdersForNthWeek = (orders: Order[] = [], n = 0) => {
  return orders.filter(({ deliveryDate }) => {
    return isWeek(deliveryDate, n);
  });
};

export const ordersBySettlement = (orders: OrderKind[]) => {
  const withKey = orders
    .filter(order => {
      return !!order.settlement;
    })
    .map(
      addKey(({ settlement }) => {
        return settlement?.settlementNumber ?? '';
      })
    );
  const groups = grouper(withKey, ['settlement'], 'orders');

  return Object.values(groups)
    .sort(
      byFunction(({ settlement: { _createdAt = '' } = {} }) => {
        return _createdAt;
      })
    )
    .reverse();
};

export const isDeliveredToConsumer = ({
  statuses
}: {
  statuses: OrderStatuses;
}) => {
  return Boolean(statuses?.deliveredToConsumer);
};

export const isOrderSmall = (order: Order) => {
  if (order.producer.minimumOrderAmount === undefined) {
    return false;
  }

  const orderTotal = getTotalOrderPrice(order.orderLines);
  return orderTotal < order.producer.minimumOrderAmount;
};

export const orderKindIsOrder = (orderKind: OrderKind): orderKind is Order => {
  return orderKind._type === 'orders';
};
