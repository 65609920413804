import { useTranslation } from '@dagens/frontend-i18n';
import { OrderUnit, PriceUnit, UnitUtils } from '@dagens/utils';
import { formatNok2Decimals } from '../../../../utils/texts';

// Convert Pricing to a human-readable string
const priceToValue = (
  priceValue: number | null,
  priceUnit: string | null,
  orderValue: number | null,
  orderUnit: string | null
) => {
  if (!priceValue || !priceUnit) {
    return null;
  }

  if (!orderUnit) {
    const basePrice = `${formatNok2Decimals(priceValue)}/${priceUnit}`;
    return basePrice;
  }

  if (!orderValue) {
    return null;
  }

  const totalPrice = `${formatNok2Decimals(priceValue * orderValue)}/${orderUnit}`;
  const orderUnitContents = `${orderValue} ${priceUnit} á`;
  const basePrice = `${formatNok2Decimals(priceValue)}/${priceUnit}`;

  return `${totalPrice} (${orderUnitContents} ${basePrice})`;
};

// Checks if the price needs to display order unit contents or not
const shouldDisplayOrderUnitContents = (
  priceUnit?: PriceUnit,
  orderEqualsPrice?: boolean
) => {
  if (priceUnit && UnitUtils.isPackedUnit(priceUnit)) {
    return false;
  }
  return !orderEqualsPrice;
};

// Converts Pricing to string
export const usePricingToString = (
  priceValue: number | null | undefined,
  priceUnit: PriceUnit | undefined,
  orderValue: number | null | undefined,
  orderUnit: OrderUnit | null | undefined,
  orderEqualsPrice: boolean | undefined
) => {
  const { t } = useTranslation();
  const withOrderUnit = shouldDisplayOrderUnitContents(
    priceUnit,
    orderEqualsPrice
  );

  const priceUnitText = priceUnit
    ? t(`units:${priceUnit}`, { count: 1 })
    : null;
  const orderUnitText = orderUnit
    ? t(`units:${orderUnit}`, { count: 1 })
    : null;

  const value = priceToValue(
    priceValue ?? null,
    priceUnitText,
    withOrderUnit ? (orderValue ?? null) : null,
    withOrderUnit ? (orderUnitText ?? null) : null
  );

  return value;
};
